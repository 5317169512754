.node circle {
	fill: #F3F3FF;
	stroke: #2593B8;
	stroke-width: 1.5px;
}

.node text {
	font-size: 11px;
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	background-color: #444;
	fill: #F4F4F4;
	text-shadow: 0 1px 4px black;
}

.node {
	cursor: pointer;
}

.node.searchIncluded text {
	font-weight: bold;
	font-size: 14px;
}

.node.searchExcluded text {
	opacity: 0.6;
}

path.link {
	fill: none;
	stroke: #2593B8;
	stroke-width: 1.5px;
}



.node span {
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	color: #F4F4F4;
	text-shadow: 0 1px 4px black;
	float: right;
}
